#MagnitudeSpectrogram ::part(spec-labels) {
  display: none;
}

body button:focus {
  outline: none;
}

body > .MuiDialog-root,
body > .MuiPopover-root,
body > .MuiTooltip-popper {
  z-index: 10000000 !important;
}

.SnackbarContainer-bottom {
  z-index: 10000001 !important;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
*::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

/* For Report.jsx */
.MuiPaper-root .data-grid tr > td:nth-child(2) {
  position: sticky;
  left: 38px;
  z-index: 1;
  background: #fff;
}
.MuiPaper-root .SheetTab1 tr > td:nth-child(3) {
  position: sticky;
  left: 260px;
  z-index: 1;
  background: #fff;
}

.MuiPaper-root .data-grid tr:nth-child(2) > td {
  position: sticky;
  top: 24px;
  background: #fff;
}
.MuiPaper-root .data-grid tr:first-child > td {
  position: sticky;
  top: 0;
  background: #999;
}
.MuiPaper-root .data-grid tr > td:first-child {
  position: sticky;
  width: 38px;
  min-width: 38px;
  left: 0;
  z-index: 1;
}
.MuiPaper-root .data-grid tr:first-child > td:first-child,
.MuiPaper-root .data-grid tr:first-child > td:nth-child(2),
.MuiPaper-root .SheetTab1 tr:first-child > td:nth-child(3) {
  top: 0;
  z-index: 2;
}
.MuiPaper-root .data-grid tr > td:nth-child(3) {
  text-align: left;
}
.MuiPaper-root .data-grid tr:nth-child(2) > td:first-child,
.MuiPaper-root .data-grid tr:nth-child(2) > td:nth-child(2),
.MuiPaper-root .SheetTab1 tr:nth-child(2) > td:nth-child(3) {
  top: 24px;
  z-index: 2;
}
